<template>
  <div class="container pt-5 mt-5">
    <select class="form-control" id="event" name="event" v-model="selectedEventId" required v-if="events" @change="refreshData">
          <option v-for="event in events" :value="event.id" :key="event.id">{{ event.name }}</option>
        </select>
     <b-badge variant="info">Total apuntados: {{ total }}</b-badge>
     <b-table dark striped hover :items="table.items" :fields="table.fields" class="mt-5 pb-5"></b-table>
  </div>
</template>

<script>
import { BTable, BBadge } from 'bootstrap-vue'
import { orderBy } from 'lodash'

export default {
  components: {
    BTable,
    BBadge
  },
  data () {
    return {
      events: [],
      selectedEventId: null,
      table: {
        fields: [
          {
            key: 'name',
            sortable: true,
            label: 'Nombre'
          },
          {
            key: 'email',
            sortable: true,
            lable: 'Email'
          },
          {
            key: 'dni',
            label: 'DNI',
            sortable: true,
          },
          {
            key: 'instagram',
            label: 'Instagram',
            sortable: true,
          },
          {
            key: 'eventName',
            label: 'Evento',
            sortable: true,
          },
          {
            key: 'date',
            label: 'Fecha',
            sortable: true,
            formatter: (value) => {
              return new Date(value).toLocaleDateString('es') + ' ' + new Date(value).toLocaleTimeString('es')
            }
          }
        ],
        items: []
      }
    }
  },
  computed: {
    total () {
      return this.table.items.length
    }
  },
  methods: {
    refreshData () {
      this.axios.get('/listEntries', { params: { eventId: this.selectedEventId }}).then((response) => {
        this.table.items = orderBy(response.data, 'date', 'desc')
      })
    }
  },
  mounted () {
    if (!this.$cookies.get('token')) {
      this.$router.push('/login')
    } else {
      this.axios.get('/admin/events', { params: { lists: false }}).then(response => {
        this.events = response.data
        this.selectedEventId = this.events[0].id
        this.refreshData()
      })
    }
  }

}
</script>

<style>

</style>